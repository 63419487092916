import React, { useState } from 'react';
import { Row, Col, Heading, Link, UsedByGraph, FbAdList, Text, cssVariables } from '../index';
import { t, formatPercentage } from '../../i18n';
import FeaturesOverviewTable from './FeaturesOverviewTable';

const FbCampaignOverview = ({ data }) => {
  const {
    organizationId,
    campaignSettingId,
    campaignName,
    campaignUrl,
    budgetPeriod,
    budgetSum,
    budgetEditLink,
    campaignOptimizationLabel,
    campaignOptimizationEditLink,
    campaignBid,
    campaignBidEditLink,
    productsCount,
    totalProductsCount,
    isCampaignSynced,
  } = data;
  const [hasAds, setHasAds] = useState(false);
  const productsAspectRatio = formatPercentage(totalProductsCount > 0 ? (productsCount / totalProductsCount) * 100 : 0);
  return (
    <div>
      <Heading spacing={8}>{t('react.fb_advantage_plus.campaign_overview.heading', { default: 'Preview' })}</Heading>
      <Row center className="mt-24" justifyBetween>
        <Col shrink>
          <Link icon="fb-campaign" href={campaignUrl} size="lg">
            {campaignName}
          </Link>
        </Col>
        <Col shrink>
          <Row center>
            <Col shrink style={{ marginRight: 0 }}>
              <UsedByGraph products={productsCount} productsTotal={totalProductsCount} />
            </Col>
            <Col>
              <Text size="sm" style={{ color: cssVariables.textSubtle }}>
                <span style={{ fontWeight: 'bold' }}>
                  {productsCount} of {totalProductsCount}
                </span>{' '}
                products <span style={{ fontWeight: 'bold' }}>({productsAspectRatio})</span>
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="FbCampaignOverview-Container">
        {hasAds && <div className="FbCampaignOverview-DecorLine" />}
        <FeaturesOverviewTable
          budgetPeriod={budgetPeriod}
          budgetSum={budgetSum}
          budgetEditLink={budgetEditLink}
          campaignOptimizationLabel={campaignOptimizationLabel}
          campaignOptimizationEditLink={campaignOptimizationEditLink}
          campaignBid={campaignBid}
          campaignBidEditLink={campaignBidEditLink}
          isCampaignSynced={isCampaignSynced}
        />
        <FbAdList
          organizationId={organizationId}
          campaignSettingId={campaignSettingId}
          showMenu={false}
          showItemsMenu={false}
          showItemsArrows
          setHasAds={setHasAds}
        />
      </div>
    </div>
  );
};

export default FbCampaignOverview;
