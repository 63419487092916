import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {
  Button,
  Row,
  Col,
  Icon,
  cssVariables,
  Text,
  UsedByGraph,
  Heading,
  ConditionsBadge,
  DropdownMenu,
  Link,
  Loader,
  EmptyState,
  SimpleTooltip,
} from '../index';
import Searchbox from '../../organizations_dropdown/Searchbox';
import { t, formatPercentage } from '../../i18n';
import { listenOnModalChangeConditions } from '../RemoteModal';
import ArrowDownFunnel from '../../../../assets/images/arrow_down_funnel.svg';

const GET_PRODUCT_SETS_QUERY = gql`
  query GetProductSetsList(
    $organizationId: BigInt!
    $campaignSettingId: BigInt!
    $dataSourceId: BigInt!
    $fulltext: String
  ) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        dataSource {
          allItemsCount
          name
        }
      }
    }
    collection(
      page: 1
      limit: 10000
      identifier: "itemGroup"
      dataSourceId: $dataSourceId
      organizationId: $organizationId
    ) {
      itemGroups(fulltext: $fulltext) {
        id
        name
        productsCount
        matchAll
        conditions {
          toDataPreview
          selectedItemGroupId
        }
      }
    }
  }
`;

const ProductSetsList = ({ organizationId, campaignSettingId, dataSourceId }) => {
  const [search, setSearch] = useState('');
  const { loading, data, refetch } = useQuery(GET_PRODUCT_SETS_QUERY, {
    variables: { organizationId, campaignSettingId, dataSourceId, fulltext: search },
  });

  useEffect(() => {
    const unsubscribe = listenOnModalChangeConditions(refetch);
    return () => {
      unsubscribe();
    };
  }, []);

  const allProductsCount = data?.organization?.campaignSetting?.dataSource?.allItemsCount || 0;
  const allItemGroups = data?.collection?.itemGroups || [];
  const allItemGroupsSorted = [...allItemGroups].sort((a, b) => {
    if (a.matchAll && !b.matchAll) {
      return -1;
    }
    if (!a.matchAll && b.matchAll) {
      return 1;
    }
    return b.id - a.id;
  });

  return (
    <>
      <div>
        <Row className="mb-16">
          <Col shrink style={{ marginRight: '30px' }}>
            <Button
              type="button"
              kind="primary"
              icon="plus"
              data-toggle="modal-url"
              data-url={`/organizations/${organizationId}/data_sources/${dataSourceId}/product_sets/new/`}
              data-heading={t('react.product_sets_list.create_new_product_set', { default: 'New product set' })}
              data-notify-on-change="new_product_set"
              data-size="fullwithGrid"
              onlyIcon
            />
          </Col>
          <Col shrink style={{ width: '200px' }}>
            <Searchbox
              search={search}
              handleSearch={({ target }) => {
                setSearch(target.value);
              }}
              debounce={400}
              searchBoxText={t('product_sets_list.search', { default: 'Search...' })}
            />
          </Col>
        </Row>

        {!!allItemGroupsSorted.length && !loading && (
          <>
            <Row
              style={{ padding: '12px', border: `1px solid ${cssVariables.interfaceOutlineBold}`, borderRadius: '8px' }}
            >
              <Col>
                <Row center>
                  <Col shrink>
                    <Icon kind="data-sources" size="12px" color={cssVariables.iconSubtle} />
                  </Col>
                  <Col>
                    <Text style={{ maxWidth: '350px' }}>{data?.organization?.campaignSetting?.dataSource?.name}</Text>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginLeft: '-25px' }}>
                <Row center>
                  <Col shrink>
                    <div
                      style={{
                        width: '18px',
                        height: '18px',
                        border: `3px solid ${cssVariables.statusNeutralDefault}`,
                        borderRadius: '50px',
                      }}
                    />
                  </Col>
                  <Col>
                    <Text style={{ color: cssVariables.textMuted }}>
                      {`${allProductsCount} ${t('product_sets_list.products', { default: 'Products' })}`}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justifyCenter style={{ marginTop: 0 }}>
              <img src={ArrowDownFunnel} alt="arrow down funnel" />
            </Row>
            {allItemGroupsSorted.map((itemGroup, i) => {
              const productsAspectRatio = formatPercentage(
                allProductsCount > 0 ? (itemGroup.productsCount / allProductsCount) * 100 : 0
              );
              const isUsedInOtherProductSets = allItemGroupsSorted.some(_itemGroup =>
                _itemGroup.conditions.find(
                  condition => condition.selectedItemGroupId?.toString() === itemGroup.id.toString()
                )
              );
              return (
                <Row
                  center
                  className={cs({ 'mb-16': i !== data?.collection?.itemGroups?.length - 1 })}
                  style={{
                    padding: '7px 8px',
                    border: `1px solid ${cssVariables.interfaceOutlineBold}`,
                    borderRadius: '8px',
                    marginTop: 0,
                  }}
                  key={itemGroup.id}
                >
                  <Col>
                    <Heading
                      tag="h3"
                      style={{
                        color: itemGroup.matchAll ? cssVariables.textGhost : cssVariables.interactivePrimaryBold,
                        cursor: itemGroup.matchAll ? 'not-allowed' : 'pointer',
                        maxWidth: '350px',
                      }}
                      size="semi-md"
                      spacing={0}
                      data-toggle={itemGroup.matchAll ? '' : 'modal-url'}
                      data-url={`/organizations/${organizationId}/data_sources/${dataSourceId}/product_sets/${itemGroup.id}/edit`}
                      data-notify-on-change="edit_product_set"
                      data-size="fullwithGrid"
                    >
                      {itemGroup.name}
                    </Heading>
                  </Col>
                  <Col style={{ marginLeft: '-40px' }}>
                    <Row center justifyBetween>
                      <Col>
                        <Row center>
                          <Col shrink style={{ marginRight: 0 }}>
                            <UsedByGraph
                              size="lg"
                              disableTooltip
                              products={parseInt(itemGroup.productsCount, 10)}
                              productsTotal={parseInt(
                                data?.organization?.campaignSetting?.dataSource?.allItemsCount,
                                10
                              )}
                            />
                          </Col>
                          <Col shrink>
                            <Text size="sm" style={{ color: cssVariables.textMuted }}>
                              <span style={{ fontWeight: 'bold' }}>
                                {itemGroup.productsCount} of {allProductsCount}
                              </span>{' '}
                              products <span style={{ fontWeight: 'bold' }}>({productsAspectRatio})</span>
                            </Text>
                          </Col>
                          <Col shrink>
                            <ConditionsBadge conditions={itemGroup.conditions} />
                          </Col>
                        </Row>
                      </Col>
                      {!itemGroup.matchAll && (
                        <Col shrink>
                          <DropdownMenu secondary>
                            <div>
                              <Link
                                href="#"
                                icon="edit"
                                data-toggle={itemGroup.matchAll ? '' : 'modal-url'}
                                data-url={`/organizations/${organizationId}/data_sources/${dataSourceId}/product_sets/${itemGroup.id}/edit`}
                                data-notify-on-change="edit_product_set"
                                data-size="fullwithGrid"
                                disabled={itemGroup.matchAll}
                              >
                                Edit
                              </Link>
                              <Link
                                href="#"
                                icon="duplicate"
                                data-toggle={itemGroup.matchAll ? '' : 'modal-url'}
                                data-url={`/organizations/${organizationId}/data_sources/${dataSourceId}/product_sets/${itemGroup.id}/duplicate`}
                                data-notify-on-change="dublicate_product_set"
                                data-size="fullwithGrid"
                                disabled={itemGroup.matchAll}
                              >
                                Duplicate
                              </Link>
                              {isUsedInOtherProductSets ? (
                                <SimpleTooltip
                                  text={t('product_sets_list.delete_tooltip', {
                                    default: 'This product set is used in other product sets, so it cannot be deleted',
                                  })}
                                >
                                  <Link href="#" icon="trash" disabled danger>
                                    Delete
                                  </Link>
                                </SimpleTooltip>
                              ) : (
                                <Link
                                  href={`/organizations/${organizationId}/data_sources/${dataSourceId}/product_sets/${itemGroup.id}`}
                                  icon="trash"
                                  data-confirm="Are you sure to delete?"
                                  data-method="delete"
                                  data-notify-on-change="delete_product_set"
                                  disabled={itemGroup.matchAll}
                                  data-remote="true"
                                  data-commit="Confirm"
                                  danger
                                >
                                  Delete
                                </Link>
                              )}
                            </div>
                          </DropdownMenu>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </div>
      {loading && (
        <Row center justifyCenter style={{ padding: '48px' }}>
          <Loader size="big" />
        </Row>
      )}
      {!loading && search && !allItemGroupsSorted.length && (
        <div className="mv-16">
          <EmptyState icon="asset_group_icon">
            <div className="mt-24">
              <Heading tag="h2" spacing={16}>
                {t('product_sets_list.no_products_found_title', { default: 'No product sets found' })}
              </Heading>
              <Text tag="div" style={{ color: cssVariables.textMuted, maxWidth: '400px', margin: '16px auto' }}>
                {t('product_sets_list.no_products_found_description', {
                  default:
                    "We couldn't find any products that match your search criteria. Check your spelling and try again or use different keyword. You can create new product set.",
                })}
              </Text>
              <Button
                icon="plus"
                kind="primary"
                data-toggle="modal-url"
                data-url={`/organizations/${organizationId}/data_sources/${dataSourceId}/product_sets/new/`}
                data-heading={t('react.product_sets_list.create_new_product_set', { default: 'New product set' })}
                data-notify-on-change="new_product_set"
                data-size="fullwithGrid"
              >
                {t('product_sets_list.no_products_found_button', { default: 'Create new product set' })}
              </Button>
            </div>
          </EmptyState>
        </div>
      )}
    </>
  );
};

ProductSetsList.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  dataSourceId: PropTypes.number.isRequired,
};

export default ProductSetsList;
