import React from 'react';
import { Heading, Row, Col } from '../index';
import { t } from '../../i18n';
import {
  FbFeedPreview,
  InstagramFeedPreview,
  FbStoriesPreview,
  InstagramStoriesPreview,
  FbReelsPreview,
  InstagramReelsPreview,
  FbRightPanelPreview,
} from './index';

const FbSingleAdPreview = ({ data }) => {
  const feedsMedia = data?.creativeSource === 'manual_upload' ? data?.feedsMedia || {} : data?.catalogMedia?.[0] || {};
  const reelsMedia = data?.creativeSource === 'manual_upload' ? data?.reelsMedia || {} : data?.catalogMedia?.[0] || {};
  const rightColumnMedia =
    data?.creativeSource === 'manual_upload' ? data?.rightColumnMedia || {} : data?.catalogMedia?.[0] || {};

  const fbPageName = data?.fbPageName || '';
  const fbPageLogo = data?.fbPageLogo || '';
  const instagramPageName = data?.instagramPageName || '';
  const instagramPageLogo = data?.instagramPageLogo || '';

  const pageLabel = t('fb_ad_preview.sponsored', { default: 'Sponsored' });

  const url = data?.url || '';
  const headline = (data?.creativeSource === 'manual_upload' ? data?.headline : data?.catalogMedia[0]?.headline) || '';
  const description =
    (data?.creativeSource === 'manual_upload' ? data?.description : data?.catalogMedia[0]?.description) || '';
  const ctaText = data?.ctaText || '';
  const primaryText =
    (data?.creativeSource === 'manual_upload' ? data?.primaryText : data?.catalogMedia[0]?.primary_text) || '';
  const showInstagramPreview = instagramPageName && instagramPageLogo;

  return (
    <div>
      <Heading className="mt-24" size="xl" tag="h3">
        {t('fb_ad_preview.feeds', { default: 'Feeds' })}
      </Heading>
      <Row padding="l" className="mb-24">
        <Col>
          <FbFeedPreview
            media={feedsMedia}
            pageName={fbPageName}
            label={pageLabel}
            pageImageUrl={fbPageLogo}
            primaryText={primaryText}
            url={url}
            headline={headline}
            description={description}
            ctaText={ctaText}
            type="single"
          />
        </Col>
        {showInstagramPreview && (
          <Col>
            <InstagramFeedPreview
              media={feedsMedia}
              pageName={instagramPageName}
              label={pageLabel}
              pageImageUrl={instagramPageLogo}
              primaryText={primaryText}
              ctaText={ctaText}
              type="single"
            />
          </Col>
        )}
      </Row>
      <div className="delimeter dashed" />
      <Heading className="mt-24" size="xl" tag="h3">
        {t('fb_ad_preview.stories', { default: 'Stories' })}
      </Heading>
      <Row padding="l" className="mb-24">
        <Col>
          <FbStoriesPreview
            media={reelsMedia}
            pageName={fbPageName}
            label={pageLabel}
            pageImageUrl={fbPageLogo}
            primaryText={primaryText}
            ctaText={ctaText}
            type="single"
          />
        </Col>
        {showInstagramPreview && (
          <Col>
            <InstagramStoriesPreview
              media={reelsMedia}
              pageName={instagramPageName}
              label={pageLabel}
              pageImageUrl={instagramPageLogo}
              ctaText={ctaText}
              type="single"
            />
          </Col>
        )}
      </Row>
      <div className="delimeter dashed" />
      <Heading className="mt-24" size="xl" tag="h3">
        {t('fb_ad_preview.reels', { default: 'Reels' })}
      </Heading>
      <Row padding="l" className="mb-24">
        <Col>
          <FbReelsPreview
            media={reelsMedia}
            pageName={fbPageName}
            label={pageLabel}
            pageImageUrl={fbPageLogo}
            primaryText={primaryText}
            url={url}
            headline={headline}
            description={description}
            ctaText={ctaText}
            type="single"
          />
        </Col>
        {showInstagramPreview && (
          <Col>
            <InstagramReelsPreview
              media={reelsMedia}
              pageName={instagramPageName}
              label={pageLabel}
              pageImageUrl={instagramPageLogo}
              primaryText={primaryText}
              url={url}
              headline={headline}
              description={description}
              ctaText={ctaText}
              type="single"
            />
          </Col>
        )}
      </Row>
      <div className="delimeter dashed" />
      <Heading className="mt-24" size="xl" tag="h3">
        {t('fb_ad_preview.right_panel_and_search', { default: 'Right panel & search' })}
      </Heading>
      <Row padding="l" className="mb-24">
        <Col>
          <FbRightPanelPreview media={rightColumnMedia} primaryText={primaryText} url={url} />
        </Col>
        <Col>
          <FbFeedPreview
            media={feedsMedia}
            pageName={fbPageName}
            label={pageLabel}
            pageImageUrl={fbPageLogo}
            primaryText={primaryText}
            url={url}
            headline={headline}
            description={description}
            ctaText={ctaText}
            type="single"
            isSearchResult
          />
        </Col>
      </Row>
    </div>
  );
};

export default FbSingleAdPreview;
